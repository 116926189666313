define("discourse/plugins/discourse-bbb/discourse/initializers/bbb", ["exports", "discourse/lib/plugin-api", "discourse/lib/show-modal", "discourse-common/lib/icon-library", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _pluginApi, _showModal, _iconLibrary, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function launchBBB($elem) {
    const data = $elem.data();
    const site = Discourse.__container__.lookup("site:main");
    const capabilities = Discourse.__container__.lookup("capabilities:main");
    (0, _ajax.ajax)("/bbb/create.json", {
      type: "POST",
      data: data
    }).then(res => {
      if (res.url) {
        if (capabilities.isAppWebview || site.mobileView && !data.mobileIframe || !site.mobileView && !data.desktopIframe) {
          window.location.href = res.url;
        } else {
          $elem.children().hide();
          $elem.append(`<iframe src="${res.url}" allowfullscreen="true" allow="camera; microphone; fullscreen; speaker" width="100%" height="500" style="border:none"></iframe>`);
        }
      }
    }).catch(function (error) {
      (0, _ajaxError.popupAjaxError)(error);
    });
  }
  function attachButton($elem) {
    const buttonLabel = $elem.data("label") || I18n.t("bbb.launch");
    $elem.html(`<button class='launch-bbb btn'>${(0, _iconLibrary.iconHTML)("video")} ${buttonLabel}</button>`);
    $elem.find("button").on("click", () => launchBBB($elem));
  }
  function attachStatus($elem, helper) {
    const status = $elem.find(".bbb-status");
    const data = $elem.data();
    (0, _ajax.ajax)(`/bbb/status/${data.meetingID}.json`).then(res => {
      if (res.avatars) {
        status.html(`<span>On the call: </span>`);
        res.avatars.forEach(function (avatar) {
          status.append(`<img src="${avatar.avatar_url}" class="avatar" width="25" height="25" title="${avatar.name}" />`);
        });
      }
    });
  }
  function attachBBB($elem, helper) {
    if (helper) {
      $elem.find("[data-wrap=discourse-bbb]").each((idx, val) => {
        attachButton($(val));
        $(val).append("<span class='bbb-status'></span>");
        attachStatus($(val), helper);
      });
    }
  }
  var _default = _exports.default = {
    name: "insert-bbb",
    initialize() {
      (0, _pluginApi.withPluginApi)("1.13.0", api => {
        const currentUser = api.getCurrentUser();
        const siteSettings = api.container.lookup("site-settings:main");
        api.decorateCooked(attachBBB, {
          id: "discourse-bbb"
        });
        if (!siteSettings.bbb_staff_only || siteSettings.bbb_staff_only && currentUser && currentUser.staff) {
          api.addComposerToolbarPopupMenuOption({
            icon: "video",
            label: "bbb.composer_title",
            action: toolbarEvent => {
              (0, _showModal.default)("insert-bbb").setProperties({
                toolbarEvent
              });
            }
          });
        }
      });
    }
  };
});